@import 'variable';
@import 'fonts';
@import 'mixins';

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: $scrollbar-bg;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: $medium-blue;
  border-radius: 10px;

  &:hover,
  &:active {
    background-color: $main-blue;
  }
}

::-moz-selection {
  color: $selection-color;
  background-color: $main-blue;
}

::selection {
  color: $selection-color;
  background-color: $main-blue;
}

body {
  padding-top: $height-header;

  &.no-scroll {
    overflow: hidden;
  }
}

.container-small {
  @media screen and (min-width: 1200px) {
    max-width: 994px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 1192px;
  }
}

img {
  &:not([src]),
  &[src=''] {
    opacity: 0;
  }
}

.ScrollbarsCustom {
  &-Track {
    background: $scrollbar-bg !important;
    border-radius: 10px !important;
    width: 6px !important;
  }

  &-Thumb {
    background-color: $medium-blue !important;
    border-radius: 10px !important;
    cursor: pointer;
    transition: background-color 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover,
    &:active {
      background-color: $main-blue !important;
    }
  }

  &-Content {
    padding-right: 25px !important;
  }

  &-TrackX {
    display: none !important;
  }

  &.left-scrollbar {
    .ScrollbarsCustom-Content {
      padding: 0 0 0 25px !important;

      @media screen and (max-width: 1199.98px) {
        padding: 0 0 0 30px !important;
      }

      @media screen and (max-width: 991.98px) {
        padding: 0 0 0 15px !important;
      }
    }

    .ScrollbarsCustom-TrackY {
      right: auto;
      left: 0;
    }
  }
}

.p-0-scrollbar {
  .ScrollbarsCustom {
    &-Wrapper {
      inset: 0 !important;
    }

    &-Content {
      padding-right: 10px !important;
    }
  }
}

body {
  font-family: $main-font;
}

h1 {
  font-family: $main-font;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.15;
  align-items: center;
  text-align: center;
  color: $text-zagolovki;
}

h2 {
  font-family: $main-font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.15;
  align-items: center;
  color: $text-zagolovki;
}

.name {
  font-family: $main-font;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.03em;
  color: $black;
}

.card {
  .name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 2.8em;
  }

  .sub-title-blue {
    //display: -webkit-box;
    //-webkit-line-clamp: 1;
    //-webkit-box-orient: vertical;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //max-height: 15px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
}

.clinic-sliders {
  .slick-list {
    padding: 10px 0;
  }
}

.price {
  font-family: $main-font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.15;
  align-items: center;
  color: $main-green;
}

.link {
  font-family: $main-font;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.25;
  text-decoration-line: underline;
  color: $text;
  cursor: pointer;
}

.link-blue {
  font-family: $main-font;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.1;
  align-items: center;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: $main-blue;
  cursor: pointer;
  @include transition-time-fastest;

  @media screen and (max-width: 1199.98px) {
    font-size: 12px;
  }

  &:hover {
    color: $medium-blue;
  }
}

//* {
//  &[data-scroll='false'] {
//    overflow: hidden !important;
//  }
//}

p {
  font-family: $main-font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.25;
  color: $text;
}

span {
  font-family: $main-font;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.15;
  color: $text;
}

.divider {
  height: 1px;
  flex: 0 0 1px;
  width: 100%;
  background: $forms;
}

.dividerBlue {
  height: 1px;
  width: 100%;
  background: $main-blue;
}

.title {
  margin-bottom: 10px;
}

.semi-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 1.15;
  text-align: center;
  color: $text-zagolovki;
  margin-bottom: 13px;

  &_left {
    @media screen and (min-width: 992px) {
      text-align: left;
    }
  }

  @media screen and (max-width: 1499.98px) {
    font-size: 30px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 28px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 24px;
  }
}

.sub-info {
  font-weight: normal;
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
  color: $text;

  &_left {
    @media screen and (min-width: 992px) {
      text-align: left;
    }
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 13px;
    line-height: 18px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 12px;
    line-height: 14px;
  }
}

.w-100 {
  width: 100% !important;
}

.sub-title-blue {
  font-family: $main-font;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.15;
  align-items: center;
  letter-spacing: 0.03em;
  color: $main-blue;
}

.many-card-slider {
  .card {
    margin-top: 15px;
    margin-bottom: 65px;
  }
}

.hide-desk-show-more {
  .many-card-slider {
    .card {
      @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-bottom: 30px;
      }
    }
  }

  .slider-footer-button {
    @media screen and (min-width: 768px) {
      display: none !important;
    }
  }
}

.blur {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: $white;
    opacity: 0.5;
  }
}

.icon {
  padding-left: $icon-size;
  height: $icon-size;
}

.text-black {
  color: $black;
}

.text-danger {
  color: $error;
}

.icon-close {
  background: url(../img/icon/close_icon.svg) no-repeat center center;
  cursor: pointer;
}

.icon-close-map-info {
  background: url(../img/icon/close-map-info.svg) no-repeat center center;
  cursor: pointer;
}

.icon-close-modal {
  background: url(../img/icon/close-modal.svg) no-repeat center center;
  cursor: pointer;
}

.icon-close-menu {
  background: url(../img/icon/close-menu.svg) no-repeat center center;
  cursor: pointer;
}

.icon-search {
  background: url(../img/icon/search_icon.svg) no-repeat center center;
}

.icon-verification {
  background: url(../img/icon/verification.svg) no-repeat center center;
  height: 20px;
  width: 20px;
}

.icon-premium {
  background: url(../img/icon/premium-icon.svg) no-repeat center center;
  height: 60px;
  width: 60px;
}

.icon-search-grey {
  background: url(../img/icon/search_icon_grey.svg) no-repeat center center;
  height: 16px;
  width: 16px;

  @media screen and (max-width: 1199.98px) {
    background-size: 14px;
  }
}

.icon-fb {
  background: url(../img/icon/facebook_icon.svg) no-repeat center center;
}

.icon-tw {
  background: url(../img/icon/twitter_icon.svg) no-repeat center center;
}

.icon-in {
  background: url(../img/icon/linkedin_icon.svg) no-repeat center center;
}

.icon-g {
  background: url(../img/icon/google_icon.svg) no-repeat center center;
}

.icon-info {
  background: url(../img/icon/info_icon.svg) no-repeat center center;
}

.icon-heart {
  background: url(../img/icon/heart_icon.svg) no-repeat center center;
}

.icon-heart-filled {
  background: url(../img/icon/heart_icon-filled.svg) no-repeat center center;
}

.icon-eye {
  background: url(../img/icon/eye_icon.svg) no-repeat center center;
}

.icon-arrow-down {
  background: url(../img/icon/arrow_down_icon.svg) no-repeat center center;
}

.icon-arrow-up {
  background: url(../img/icon/arrow_up_icon.svg) no-repeat center center;
}

.icon-arrow-down-blue {
  background: url(../img/icon/arrow_down_blue_icon.svg) no-repeat center center;
}

.icon-arrow-up-blue {
  background: url(../img/icon/arrow_up_blue_icon.svg) no-repeat center center;
}

.icon-arrow-left-thin {
  background: url(../img/icon/arrow_left_icon.svg) no-repeat center center;
}

.icon-arrow-left {
  background: url(../img/icon/arrow_left_icon-bold.svg) no-repeat center center;
}

.icon-arrow-right-thin {
  background: url(../img/icon/arrow_right_icon.svg) no-repeat center center;
}

.icon-arrow-right {
  background: url(../img/icon/arrow_right_icon-bold.svg) no-repeat center center;
}

.icon-eye-active {
  background: url(../img/icon/eye_icon_active.svg) no-repeat center center;
}

.icon-user-blue {
  background: url(../img/icon/user_blue_icon.svg) no-repeat center center;
}

.icon-check-blue {
  background: url(../img/icon/check_blue_icon.svg) no-repeat center center;
}

.icon-plus-small {
  background: url(../img/icon/plus-small.svg) no-repeat center center;
}

.icon-minus-small {
  background: url(../img/icon/minus-small.svg) no-repeat center center;
}

.icon-remove {
  background: url(../img/icon/remove-icon.svg) no-repeat center center;
}

.icon-load-more {
  background: url(../img/icon/load-more-icon.svg) no-repeat center center;
}

.icon-circle-check-disabled {
  background: url(../img/icon/circle-check-disabled.svg) no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1199.98px) {
    width: 20px;
    height: 20px;
  }
}

.icon-circle-check-active {
  background: url(../img/icon/circle-check-active.svg) no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;

  @media screen and (max-width: 1199.98px) {
    width: 20px;
    height: 20px;
  }
}

.icon-professional-blue {
  background: url(../img/icon/professional_blue_icon.svg) no-repeat center center;
}

.icon-settings-blue {
  background: url(../img/icon/settings_blue_icon.svg) no-repeat center center;
}

.icon-home-blue {
  background: url(../img/icon/home_blue_icon.svg) no-repeat center center;
}

.icon-profile-blue {
  background: url(../img/icon/profile_blue_icon.svg) no-repeat center center;
}

.icon-favorites-blue {
  background: url(../img/icon/favorites_blue_icon.svg) no-repeat center center;
}

.filter-icon {
  background: url(../img/icon/filter-icon.svg) no-repeat center center;
}

.filter-white-icon {
  background: url(../img/icon/filter-icon-white.svg) no-repeat center center;
}

.mobile-filter-icon {
  background: url(../img/icon/mobile-filter-icon.svg) no-repeat center center;
}

.mobile-show-list-icon {
  background: url(../img/icon/mobile-show-list-icon.svg) no-repeat center center;
}

.icon-help-blue {
  background: url(../img/icon/help_blue_icon.svg) no-repeat center center;
}

.icon-clinic-blue {
  background: url(../img/icon/clinic_blue_icon.svg) no-repeat center center;
}

.icon-service-blue {
  background: url(../img/icon/treatments_blue_icon.svg) no-repeat center center;
}

.icon-specialist-blue {
  background: url(../img/icon/specialists_blue_icon.svg) no-repeat center center;
}

.icon-business-blue {
  background: url(../img/icon/business_blue_icon.svg) no-repeat center center;
}

.icon-info-blue {
  background: url(../img/icon/info_blue_icon.svg) no-repeat center center;
}

.icon-location-blue {
  background: url(../img/icon/location_blue_icon.svg) no-repeat center center;
}

.icon-lock-blue {
  background: url(../img/icon/lock_blue_icon.svg) no-repeat center center;
}

.icon-edit-blue {
  background: url(../img/icon/edit_blue_icon.svg) no-repeat center center;
}

.icon-insurance-blue {
  background: url(../img/icon/insurance_blue_icon.svg) no-repeat center center;
}

.icon-tech-blue {
  background: url(../img/icon/tech_blue_icon.svg) no-repeat center center;
}

.icon-upload-blue {
  background: url(../img/icon/upload_blue_icon.svg) no-repeat center center;
}

.icon-award-blue {
  background: url(../img/icon/awards_blue_icon.svg) no-repeat center center;
}

.icon-email-blue {
  background: url(../img/icon/email_blue_icon.svg) no-repeat center center;
}

.icon-publication-blue {
  background: url(../img/icon/publication_blue_icon.svg) no-repeat center center;
}

.icon-speciality-blue {
  background: url(../img/icon/speciality_blue_icon.svg) no-repeat center center;
}

.icon-phone-blue {
  background: url(../img/icon/phone_blue_icon.svg) no-repeat center center;
}

.pointer {
  cursor: pointer;
}

li {
  list-style: none;
}

.list-style {
  li {
    position: relative;
    padding-left: 15px;
    font-family: $main-font;
    font-weight: normal;
    font-size: 13px;
    line-height: 1.4;
    color: $text-zagolovki;
    margin-bottom: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 1px;
      width: 5px;
      height: 5px;
      background: $main-blue;
      border-radius: 50%;
    }
  }

  &_small {
    li {
      color: $text;
      font-size: 12px;
      margin-bottom: 4px;
      padding-left: 9px;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 1px;
        width: 3px;
        height: 3px;
        background: $medium-blue;
        border-radius: 50%;
      }
    }
  }
}

.numeric-list {
  list-style: none;
  counter-reset: my-awesome-counter;

  li {
    counter-increment: my-awesome-counter;
    font-size: 14px;
    line-height: 1.4;
    color: $black;
    margin-bottom: 18px;
    position: relative;
    padding-left: 15px;

    &::before {
      content: counter(my-awesome-counter) '. ';
      color: $main-blue;
      font-weight: bold;
      margin-right: 12px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.list-compare {
  li {
    counter-increment: my-awesome-counter;
    font-size: 14px;
    line-height: 1.4;
    color: $black;
    position: relative;
    padding-left: 29px;

    @media screen and (max-width: 1199.98px) {
      font-size: 13px;
    }

    @media screen and (max-width: 991.98px) {
      padding-left: 24px;
    }

    @media screen and (max-width: 767.98px) {
      font-size: 12px;
      line-height: 1.3;
      padding-left: 27px;
    }

    &:not(:last-child) {
      margin-bottom: 11px;

      @media screen and (max-width: 1199.98px) {
        margin-bottom: 10px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
    }
  }

  &.list-advantage {
    li {
      &::before {
        background: url(../img/icon/advantage-list-icon.svg) no-repeat center center;
        width: 12px;
        height: 12px;
      }
    }
  }

  &.list-disadvantage {
    li {
      &::before {
        background: url(../img/icon/disadvantage-list-icon.svg) no-repeat center center;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  background: #f1f1f1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #aaa;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

input,
button,
select {
  @include remove-outline;
}

.slider-mobile-overflow {
  .slick-list {
    overflow: inherit;
  }
}

//slick slider padding clinic cards
.clinic-sliders {
  .slick-list {
    padding: 10px 0;
  }
}

//slick slider dots && slick slider arrows
.slick-slider {
  .slick-arrow {
    width: 24px;
    height: 24px;

    @media screen and (max-width: 1199.98px) {
      display: none !important;
    }

    &::before {
      content: none !important;
    }

    &.slick-prev {
      background: url(../img/icon/prev-arrow-slider.svg) no-repeat center center;

      @media screen and (min-width: 1200px) {
        left: -45px;
      }
    }

    &.slick-next {
      background: url(../img/icon/next-arrow-slider.svg) no-repeat center center;

      @media screen and (min-width: 1200px) {
        right: -45px;
      }
    }

    &.slick-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &.coming-soon-arrows {
    .slick-arrow {
      z-index: 1;
      width: 16px;
      height: 16px;
      display: block !important;
      top: calc(40% + 7px);
      @include transition-time;

      @media screen and (min-width: 768px) {
        width: 26px;
        height: 26px;
      }

      @media screen and (min-width: 1200px) {
        top: calc(50% + 5px);
      }

      @media screen and (min-width: 1500px) {
        top: calc(40% - 13px);
      }

      &:hover {
        opacity: 0.75;
      }

      &:active {
        opacity: 0.5;
      }

      &.slick-prev {
        background: url(../img/icon/prev-arrow-slider-white.svg) no-repeat center center;
        background-size: contain;
        left: 10px;

        @media screen and (min-width: 768px) {
          left: 15px;
        }

        @media screen and (min-width: 1920px) {
          left: 246px;
        }
      }

      &.slick-next {
        background: url(../img/icon/next-arrow-slider-white.svg) no-repeat center center;
        background-size: contain;
        right: 10px;

        @media screen and (min-width: 768px) {
          right: 15px;
        }

        @media screen and (min-width: 1920px) {
          right: 246px;
        }
      }
    }
  }
}

.slick-dots {
  position: relative;
  bottom: 0;
  margin-top: 5px;

  li {
    margin: 0;
    @include transition-time;

    &:hover {
      transform: scale(1.3);
    }

    button {
      &::before {
        color: $dots-color;
        opacity: 1;
        font-size: 7px;
      }
    }

    &.slick-active {
      button {
        &::before {
          color: $dots-color-active;
          opacity: 1;
        }
      }
    }
  }
}

.large-dots {
  .slick-dots {
    li {
      button {
        &::before {
          @media screen and (min-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.slick-dots-white {
  .slick-dots {
    li {
      height: 10px;
      width: 10px;

      button {
        height: 10px;
        width: 10px;

        &::before {
          color: $medium-blue;
          height: 10px;
          width: 10px;
          line-height: 10px;
        }
      }

      &.slick-active {
        button {
          &::before {
            color: $white;
          }
        }
      }
    }
  }
}

.coming-soon-dots {
  .slick-dots {
    position: absolute;
    top: auto;
    bottom: 35px;
    left: 0;

    @media screen and (min-width: 576px) {
      left: 60px;
      margin: 0;
      bottom: 24px;
      width: auto;
    }

    @media screen and (min-width: 768px) {
      left: 152px;
    }

    @media screen and (min-width: 992px) {
      left: 195px;
    }

    @media screen and (min-width: 1200px) {
      left: 167px;
    }

    @media screen and (min-width: 1500px) {
      left: 340px;
    }
  }
}

//DatePicker icon
.ant-picker-suffix {
  position: absolute;
  right: 15px;
}

.ant-picker-clear {
  right: 15px !important;
}

//align images
.align-wrapper {
  img {
    &.aligncenter {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 18px;
    }

    &.alignleft {
      margin-left: 0;
      margin-right: 18px;
      margin-bottom: 18px;
      text-align: left;
      float: left;
    }

    &.alignright {
      margin-left: 18px;
      margin-right: 0;
      margin-bottom: 18px;
      text-align: right;
      float: right;
    }

    &.alignnone {
      margin-bottom: 18px;
    }
  }
}
