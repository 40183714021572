@import '../../assets/css/variable';
@import '../../assets/css/mixins';

.background {
  background: $light-blue-bg;
}

.wrapper {
  padding: 130px 0 110px;

  @media screen and (max-width: 1499.98px) {
    padding: 66px 0 60px;
  }

  @media screen and (max-width: 1199.98px) {
    padding: 82px 0;
  }

  @media screen and (max-width: 991.98px) {
    padding: 160px 0;
  }

  @media screen and (max-width: 767.98px) {
    padding: 65px 0 105px;
  }

  .image {
    display: flex;
    justify-content: center;
    margin: 0 0 100px 133px;
    color: $main-blue;
    animation: textAnimation 6s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    filter: drop-shadow(0 0 15px transparent);

    @keyframes textAnimation {
      50% {
        opacity: 0.6;
        filter: drop-shadow(0 0 15px $main-blue);
      }
    }

    @media screen and (max-width: 1499.98px) {
      margin: 0 0 45px 99px;
    }

    @media screen and (max-width: 1199.98px) {
      margin: 0 0 48px 72px;
    }

    @media screen and (max-width: 991.98px) {
      margin: 0 0 55px 67px;
    }

    @media screen and (max-width: 767.98px) {
      margin: 0 0 36px 0;
    }

    svg {
      @media screen and (max-width: 1499.98px) {
        width: 515px;
        height: 200px;
      }

      @media screen and (max-width: 1199.98px) {
        width: 463px;
        height: 180px;
      }

      @media screen and (max-width: 767.98px) {
        width: 100%;
        height: 100%;
        max-width: 310px;
        max-height: 120px;
      }
    }
  }

  .info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.2;
    color: $text-zagolovki;
    margin-bottom: 6px;

    @media screen and (max-width: 767.98px) {
      margin-right: 35px;
    }
  }

  .description {
    font-size: 16px;
    line-height: 1.2;
    color: $text-zagolovki;
    margin-bottom: 25px;

    @media screen and (max-width: 1199.98px) {
      font-size: 14px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 767.98px) {
      font-size: 12px;
      margin-bottom: 13px;
    }
  }

  .info {
    .infoBody {
      display: flex;
      align-items: center;
      margin-right: 50px;
      text-align: center;

      @media screen and (max-width: 1499.98px) {
        margin-right: 55px;
      }

      @media screen and (max-width: 1199.98px) {
        margin-right: 35px;
      }

      @media screen and (max-width: 991.98px) {
        margin-right: 45px;
      }

      @media screen and (max-width: 767.98px) {
        margin-right: 0;
      }
    }

    .infoImage {
      margin-right: 13px;
      flex: 0 0 40px;

      @media screen and (max-width: 1499.98px) {
        margin-right: 13px;
      }

      @media screen and (max-width: 1199.98px) {
        flex: 0 0 30px;
      }

      @media screen and (max-width: 767.98px) {
        margin-right: 12px;
        flex: 0 0 25px;
      }

      svg {
        @media screen and (max-width: 1199.98px) {
          width: 30px;
          height: 33px;
        }

        @media screen and (max-width: 767.98px) {
          width: 25px;
          height: 25px;
        }
      }
    }

    .buttonWrapper {
      button {
        @media screen and (min-width: 768px) {
          height: 35px;
          padding: 0 50px;
          font-size: 13px;
        }

        @media screen and (min-width: 1200px) {
          height: 40px;
          font-size: 14px;
        }

        @media screen and (max-width: 767.98px) {
          padding: 0 35px;
        }
      }
    }
  }
}
